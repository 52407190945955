import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles";
import useAuthContext from "../../hooks/useAuthContext";
import Button from "@material-ui/core/Button";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useRateContext from "../../hooks/useRateContext";
import RateProvider from "../../providers/RateProvider";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import apiClient from "../../auth/apiClient";
import UserService from "../../services/UserService";
import GPIOService from "../../services/GPIOService";
import useHubContext from "../../hooks/useHubContext";
import { PORTAL_TRIGGER, RATE_CHANGE_TOPIC } from "../../constants";
import { Divider, Tooltip } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Title from "../Title";
import clsx from "clsx";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { setEntityProperty, FindEntity } from "../../state/slices/entities";
import * as c from "../../constants";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectEntityById, setEntityProperty as coreSetEntityProperty} from "../../state/slices/CoreEntity";

export const rateSelectorInsideSchema = (isEntryDevice) => Yup.object().shape({
  selectedRate: Yup.string()
    .when(['selectedSecondaryRate', 'selectedSecondaryRateTrigger'], {
      is: (selectedSecondaryRate, selectedSecondaryRateTrigger) =>
        isEntryDevice &&
        ((selectedSecondaryRate && selectedSecondaryRate.length > 0) ||
          (selectedSecondaryRateTrigger && selectedSecondaryRateTrigger.length > 0)),
      then: Yup.string().required("Required when Secondary Rate or Trigger is used"),
      otherwise: Yup.string().notRequired(),
    }),
  selectedSecondaryRate: Yup.string()
    .when(['selectedSecondaryRateTrigger'], {
      is: (selectedSecondaryRateTrigger) => (selectedSecondaryRateTrigger && selectedSecondaryRateTrigger.length > 0),
      then: Yup.string().required("Required when Secondary Rate Trigger is used"),
      otherwise: Yup.string().notRequired(),
    }),
  selectedSecondaryRateTrigger: Yup.string()
    .when(['selectedSecondaryRate'], {
      is: (selectedSecondaryRate) => (selectedSecondaryRate && selectedSecondaryRate.length > 0),
      then: Yup.string().required("Required when Secondary Rate is used"),
      otherwise: Yup.string().notRequired(),
    }),
}
  , [
    ['selectedRate', 'selectedSecondaryRate'],
    ['selectedRate', 'selectedSecondaryRateTrigger'],
    ['selectedSecondaryRate', 'selectedSecondaryRateTrigger']
  ]
);

const defaultRateSelectorInside = {
  selectedRate: "",
  selectedSecondaryRate: "",
  selectedSecondaryRateTrigger: "",
};

const userService = new UserService(apiClient);
const gpioService = new GPIOService(apiClient);
const emptyGuid = "00000000-0000-0000-0000-000000000000";

const RateSelector = (props) => {
  return (
    <RateProvider>
      <RateSelectorInside {...props} />
    </RateProvider>
  );
};

export const RateSelectorInside = ({
  entityID: givenEntityID,
  onSelect,
  onClose,
  inline,
  inlineClass,
  selected,
  onRateBlur,
  disabled,
}) => {
  const classes = styles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { functions } = useRateContext();
  const {
    getAvailableRates,
    getRateAssignedToEntity,
    assignRateToEntityV2,
    unassignRateFromEntity,
  } = functions;
  const { authReducer } = useAuthContext();
  const [authState] = authReducer;
  const currentUserId = authState.userSession?.idToken?.payload?.userid;
  const [currentUserName, setCurrentUserName] = useState("");
  const { portalHub } = useHubContext();
  const { facilityID } = useCurrentFacility();
  const useCoreEntitySlice = useCoreEntityContext();

  const entityID = givenEntityID || facilityID;
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || entityID);
  const entity = useSelector(state => {
    return useCoreEntitySlice ? selectEntityById(state,entityID) : FindEntity(state.entities?.EntityList ?? [], entityID);
  });
  const [currentEntity, setCurrentEntity] = useState(null);
  const [availableRates, setAvailableRates] = useState([]);
  const [originalRate, setOriginalRate] = useState("");
  const [GPIOMappings, setGPIOMappings] = useState([]);
  const EditRatePermission = true;
  const dispatch = useDispatch();
  const deviceMode = _.find(entity?.settings ?? [],
    { name: "devicemode" }
  )?.value;
  const isEntryDevice = deviceMode == c.DEVICE_MODE.ENTRY;
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    trigger
  } = useForm({
    defaultValues: defaultRateSelectorInside,
    resolver: yupResolver(rateSelectorInsideSchema(isEntryDevice)),
    mode: 'onChange'
  });

  const formTriggerArray = [
    `selectedRate`,
    `selectedSecondaryRate`,
    `selectedSecondaryRateTrigger`
  ];

  async function assignSelectedRate(data) {
    var method = data.selectedRate == "" || data.selectedRate == emptyGuid ? unassignRateFromEntity : assignRateToEntityV2;
    const details = {
      RateID: data.selectedRate,
      EntityID: entityID,
      entityRateDetails: data.selectedSecondaryRate == "" || data.selectedSecondaryRateTrigger == "" ? null :
        {
          SecondaryRateID: data.selectedSecondaryRate,
          SecondaryRateTriggerID: data.selectedSecondaryRateTrigger
        }
    };

    method(entityID, details)
      .then(() => {
        getCurrentUserName();
        portalHub.invoke(PORTAL_TRIGGER, {
          entityID: entityID,
          topic: RATE_CHANGE_TOPIC,
          method: "rate",
          message: JSON.stringify({
            user: currentUserName,
            timeOfChange: Date.now(),
            oldRate: originalRate,
            newRate: data.selectedRate,
          }),
        });

        if (entity?.typeid === c.ENTITY_TYPE.Device) {
          dispatch(useCoreEntitySlice ?
            coreSetEntityProperty({
              entityid: entityID,
              property: 'details',
              value: {
                ...entity?.details,
                rateid: data.selectedRate
              }
            })
            :
            setEntityProperty({
              entityid: entityID,
              property: 'details',
              value: {
                ...entity?.details,
                rateid: data.selectedRate
              }
            })
          )
        }
        onClose();
      })
      .catch(() => {
        enqueueSnackbar(`There was an issue while trying to save`, {
          variant: "error", tag: "FailedToSave",
        });
      });
  }

  const GetGPIOMappingInfo = async () => {
    try {
      var result = await gpioService.getIOMappings(entityID);
      setGPIOMappings(result.data);
    } catch (error) {
      enqueueSnackbar("Failed to get GPIOMappings", {
        variant: "error",
        tag: "getGPIOError"
      });
    }
  }

  useEffect(() => {
    GetGPIOMappingInfo();
  }, [entityID])

  const getCurrentUserName = () => {
    userService
      .getUser(currentUserId)
      .then((result) => {
        setCurrentUserName(`${result.data.firstName} ${result.data.lastName}`);
      })
      .catch(async (error) => {
        setCurrentUserName("");
      });
  };

  useEffect(() => {
    if (currentEntity != entityID) {
      getAvailableRates(scopeAwareFacilityID, 1)
        .then((response) => {
          setAvailableRates(response.data);
        })
        .catch((err) => {
          setAvailableRates();
        });
      getRateAssignedToEntity(entityID)
        .then((response) => {
          setValue("selectedRate", response.data.rateID == emptyGuid ? '' : response.data.rateID);
          setOriginalRate(response.data.rateID);
          setValue("selectedSecondaryRate", response.data.entityRateDetails.secondaryRateID);
          setValue("selectedSecondaryRateTrigger", response.data.entityRateDetails.secondaryRateTriggerID);
        })
        .catch((err) => { });
      setCurrentEntity(entityID);
    }
  }, [authState.currentProperty, entityID, scopeAwareFacilityID]);

  // If we have passed in a rateBlobID through selected, display the
  // associated rate in the dropdown
  useEffect(() => {
    var rateIdOfSelected = selected
      ? availableRates?.find((x) => x.rateBlobID == selected)?.rateID
      : "";

    if (rateIdOfSelected == "") return;

    setValue("selectedRate", rateIdOfSelected);
  }, [selected, availableRates]);

  var rateMenuitems = [];
  rateMenuitems.push(
    <MenuItem value="" key="none">
      <em>None</em>
    </MenuItem>
  );
  for (var i = 0; i < availableRates?.length; i++) {
    rateMenuitems.push(
      <MenuItem value={availableRates[i].rateID} key={availableRates[i].rateID}>
        {availableRates[i].name}
      </MenuItem>
    );
  }

  var rateTriggerMenuitems = [];
  rateTriggerMenuitems.push(
    <MenuItem value="" key="none">
      <em>None</em>
    </MenuItem>
  );
  for (var i = 0; i < GPIOMappings?.length; i++) {
    if (GPIOMappings[i].gpioType === "Input") {
      rateTriggerMenuitems.push(
        <MenuItem value={GPIOMappings[i].ioMappingID} key={GPIOMappings[i].ioMappingID}>
          {GPIOMappings[i].friendlyName}
        </MenuItem>
      );
    }
  }

  const handleSecondaryChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await setValue(name, value);
    trigger(formTriggerArray)
  };

  return (
    <div style={{ minWidth: 400, padding: inline === true ? 0 : 20 }}>
      {/*<LostTicketRateForm entityID={entityID} />*/}
      {inline === false && (
        <>
          <Title>Rate Selection</Title>
          <Divider style={{ marginBottom: 15 }}></Divider>
        </>
      )}
      <div xs={12}>
        <Controller
          name="selectedRate"
          control={control}
          render={({ field }) => (
            <FormControl
              className={
                inlineClass
                  ? clsx(inlineClass, classes.formControl)
                  : classes.formControl
              }
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {isEntryDevice ? "Primary Rate" : "Rate"}
              </InputLabel>
              <Select
                {...field}
                labelId="select-primary-rate"
                id="select-primary-rate"
                value={field?.value}
                label="selectedRate"
                style={{ minWidth: 200 }}
                disabled={!EditRatePermission || disabled}
                data-testid="select-primary-rate-test"
                onChange={handleSecondaryChange}
              >
                {rateMenuitems}
              </Select>
              {errors.selectedRate && (
                <div className={clsx([classes.selectError])}>
                  {errors.selectedRate.message}
                </div>
              )}
            </FormControl>
          )}
        />
      </div>
      {deviceMode === c.DEVICE_MODE.ENTRY && (
        <div xs={12}>
          <Controller
            name="selectedSecondaryRate"
            control={control}
            render={({ field }) => (
              <FormControl
                className={
                  inlineClass
                    ? clsx(inlineClass, classes.formControl)
                    : classes.formControl
                }
              >
                <Tooltip
                  placement="left"
                  title="The secondary rate requires an external trigger. Select the desired rate that will be issued on tickets while the secondary rate is active."
                >
                  <span>
                    <InputLabel id="demo-simple-select-outlined-label-2">Secondary Rate</InputLabel>
                    <Select
                      {...field}
                      labelId="select-secondary-rate"
                      id="select-secondary-rate"
                      value={field?.value}
                      label="selectedSecondaryRate"
                      style={{ minWidth: 200 }}
                      disabled={!EditRatePermission || disabled}
                      data-testid="select-secondary-rate-test"
                      onChange={handleSecondaryChange}
                    >
                      {rateMenuitems}
                    </Select>
                    {errors.selectedSecondaryRate && (
                      <div className={clsx([classes.selectError])}>
                        {errors.selectedSecondaryRate.message}
                      </div>
                    )}
                  </span>
                </Tooltip>
              </FormControl>
            )}
          />
          <Controller
            name="selectedSecondaryRateTrigger"
            control={control}
            render={({ field }) => (
              <FormControl
                className={
                  inlineClass
                    ? clsx(inlineClass, classes.formControl)
                    : classes.formControl
                }
              >
                <Tooltip
                  placement="left"
                  title="The secondary rate requires an external trigger. Select the desired GPIO input mapping that will activate the secondary rate."
                >
                  <span>
                    <InputLabel id="demo-simple-select-outlined-label-2">Secondary Rate Trigger</InputLabel>
                    <Select
                      {...field}
                      labelId="select-secondary-rate-trigger"
                      id="select-secondary-rate-trigger"
                      value={field?.value}
                      label="selectedSecondaryRateTrigger"
                      style={{ minWidth: 200 }}
                      disabled={!EditRatePermission || disabled}
                      data-testid="select-secondary-rate-trigger-test"
                      onChange={handleSecondaryChange}
                    >
                      {rateTriggerMenuitems}
                    </Select>
                    {errors.selectedSecondaryRateTrigger && (
                      <div className={clsx([classes.selectError])}>
                        {errors.selectedSecondaryRateTrigger.message}
                      </div>
                    )}
                  </span>
                </Tooltip>
              </FormControl>
            )}
          />
        </div>
      )}
      <div></div>
      {inline === false && <Divider style={{ marginTop: 20 }} />}
      {inline === false && (
        <div style={{ marginTop: 30, clear: "both", float: "right" }}>
          {EditRatePermission && (
            <Button
              key="savebutton"
              variant="contained"
              startIcon={<SaveIcon />}
              color="primary"
              onClick={handleSubmit(assignSelectedRate)}
            >
              Save
            </Button>
          )}
          <Button
            key="closebutton"
            variant="contained"
            onClick={onClose}
            style={{ marginLeft: 5 }}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

RateSelector.defaultProps = {
  onClose: () => { },
  onSelect: () => { },
  onRateBlur: () => { },
  inline: false,
};

RateSelector.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  inline: PropTypes.bool,
  onRateBlur: PropTypes.func,
};

export default RateSelector;
