import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
  Dialog,
  Drawer
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweetAlt, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faBolt } from "@fortawesome/pro-light-svg-icons";
import { faBolt as faBoltFilled } from "@fortawesome/pro-solid-svg-icons";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { faUserPlus, faCashRegister, faArrowUpRightAndArrowDownLeftFromCenter } from "@fortawesome/pro-duotone-svg-icons";
import { yellow } from "@material-ui/core/colors";
import _ from "lodash";
import useHasPermissions from "../../hooks/useHasPermissions";
import { IconButton } from "@material-ui/core";
import QuickActionsPulseDialog from "./QuickActionsPulseDialog";
import EndShiftPulseDialog from "./EndShiftPulseDialog";
import ReversingLanesDrawer from "./ReversingLanesDrawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import clsx from "clsx";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useCurrentUser from "../../hooks/useCurrentUser";
import { changeFacilities } from "../../state/slices/entities";
import { changeNewFacilities } from "../../state/slices/CoreEntity";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";

const QuickActionsButton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { cashieredDevices } = useFlags();
  const useCoreEntitySlice = useCoreEntityContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(isMediumScreen);
  const [endShiftConfirmOpen, setEndShiftConfirmOpen] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const shiftSessionID = useSelector((state) => state.shiftSession?.shiftID);
  const endShiftAfterTransaction = useSelector((state) => state.shiftSession?.endShiftAfterTransaction);
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const OpenGatePermission = hasPermissions(["overview.opengates"]);
  const AddAccessHolderPermission = hasPermissions(["accessholders.add"]);
  const PayOnEntryCashier = hasPermissions(["cashiermodes.payonentrycashier"]);
  const RoamingCashier = hasPermissions(["cashiermodes.roamingcashier"]);
  const ControlReversingLane = hasPermissions(["overview.controlreversinglanes"]);
  const shiftSession = useSelector((state) => state.shiftSession);
  const { facilityID } = useCurrentFacility();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const ShowCashierSession = cashieredDevices && ((PayOnEntryCashier || RoamingCashier) && shiftSession?.shiftID > 0 && !endShiftAfterTransaction);
  const AnyQualifyingPermission = OpenGatePermission || AddAccessHolderPermission || PayOnEntryCashier || RoamingCashier;

  const handleAccessHolderAdd = () => {
    handleClose();
    history.push("/contracts/accessholders/details", { type: "ADD_ACCESS_HOLDER" });
  };

  const handleCashierSessionClick = async () => {
    handleClose();
    if(facilityID != shiftSession?.entityID) {
      await dispatch(
        useCoreEntitySlice ?
          changeNewFacilities({ facilityID: shiftSession?.entityID, userID: currentUser.UserID })
        :
          changeFacilities({ facilityID: shiftSession?.entityID, userID: currentUser.UserID })
      );
    }
    history.push("/cashieredDevice/start");
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePulseCommand = () => {
    setOpen(true);
  };

  const handleEndShift = () => {
    setEndShiftConfirmOpen(true);
  }

  return (
    <>
      {AnyQualifyingPermission && (
        <>
          {!isMediumScreen ? (
            <Button
              className={clsx([classes.button, "quick-actions-btn"])}
              startIcon={<FontAwesomeIcon icon={ ShowCashierSession ? faBoltFilled : faBolt } color={ ShowCashierSession ? yellow[400] : "" } />}
              onClick={handleClick}
            >
              Quick Actions
            </Button>
          ) : (
            <IconButton
              onClick={handleClick}
              className={clsx([classes.button, "quick-actions-btn"])}
              title="Quick Actions"
            >
              <FontAwesomeIcon icon={ ShowCashierSession ? faBoltFilled : faBolt } color={ ShowCashierSession ? yellow[400] : "" } />
            </IconButton>
          )}
        </>
      )}
      <Menu
        className={clsx("quick-actions-menu")}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography style={{ padding: 6, paddingLeft: 14 }} color="primary">
          <FontAwesomeIcon
            icon={faBoltFilled}
            style={{ marginRight: 15 }}
            color={ ShowCashierSession ? yellow[400] : "" }
            swapOpacity
          />
          Quick Actions
        </Typography>
        <Divider />
        {AddAccessHolderPermission && (
          <MenuItem
            className={clsx("quick-actions-add-access-holder")}
            onClick={handleAccessHolderAdd}
          >
            <ListItemIcon style={{ marginRight: -20 }}>
              <FontAwesomeIcon icon={faUserPlus} swapOpacity />
            </ListItemIcon>
            <ListItemText>
              <Box style={{ position: "relative" }}>
                <Typography color="primary">New Access Holder</Typography>
              </Box>
              <Typography style={{ fontSize: "smaller" }}>
                Create a new access holder
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
        {OpenGatePermission && (
          <MenuItem
            className={clsx("quick-actions-pulse-gate")}
            onClick={handlePulseCommand}
          >
            <ListItemIcon style={{ marginRight: -20 }}>
              <FontAwesomeIcon icon={faRetweetAlt} swapOpacity />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary">Cycle Gate</Typography>
              <Typography style={{ fontSize: "smaller" }}>
                Select a device to cycle its gate
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
        {ShowCashierSession && (
          <MenuItem
            className={clsx("quick-actions-cashier-session")}
            onClick={handleCashierSessionClick}
          >
            <ListItemIcon style={{ marginRight: -20 }}>
              <FontAwesomeIcon icon={faCashRegister} swapOpacity />
            </ListItemIcon>
            <ListItemText>
              <Box style={{ position: "relative" }}>
                <Typography color="primary">Cashier Shift</Typography>
              </Box>
              <Typography style={{ fontSize: "smaller" }}>
                Return to your active shift
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
        {cashieredDevices && shiftSessionID > 0 && !endShiftAfterTransaction && (PayOnEntryCashier || RoamingCashier) && (
          <MenuItem
            className={clsx("quick-actions-end-shift")}
            onClick={handleEndShift}
          >
            <ListItemIcon style={{ marginRight: -20 }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary">End Shift</Typography>
              <Typography style={{ fontSize: "smaller" }}>
                End your shift
                </Typography>
            </ListItemText>
          </MenuItem>
        )}
        {ControlReversingLane && (
          <MenuItem
          className={clsx("quick-actions-reversing-lanes")}
          onClick={()=>{setDrawerState(true);}}
          >
            <ListItemIcon style={{ marginRight: -20 }}>
              <FontAwesomeIcon icon={faArrowUpRightAndArrowDownLeftFromCenter} swapOpacity />
            </ListItemIcon>
            <ListItemText>
              <Box style={{ position: "relative" }}>
                <Typography color="primary">Reversing Lanes</Typography>
              </Box>
              <Typography style={{ fontSize: "smaller" }}>
                Manage reversing lane direction
              </Typography>
            </ListItemText>
        </MenuItem>        
        )}
      </Menu>
      <Dialog
        open={open}
        disableScrollLock={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        className={clsx("quick-actions-pulse-gate-dialog")}
        style={{ overflow: "hidden" }}
      >
        <QuickActionsPulseDialog
          onClose={() => {
            setOpen(false);
          }}
        />
      </Dialog>

      <Dialog
        open={endShiftConfirmOpen}
        disableScrollLock={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        className={clsx("quick-actions-end-shift-dialog", "dialog-confirmation")}
        style={{ overflow: "hidden" }}
      >
        <EndShiftPulseDialog
          onClose={() => {
            setEndShiftConfirmOpen(false);
            handleClose();
          }}
        />
      </Dialog>
      <Drawer
        className={clsx("drawer", "control-reversing-lane")}
        anchor="right"
        open={drawerState}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={()=>{}}
      >
        <ReversingLanesDrawer
          onClose={()=>{setDrawerState(false);}}
        />
      </Drawer>
    </>
  );
};

export default QuickActionsButton;
